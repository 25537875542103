import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { useBreakpoint } from "gatsby-plugin-breakpoints/BreakpointProvider"

const ExecutiveCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 280px;
    margin: 15px;
    margin-top: 50px;
`

const ExecutiveImageWrapper = styled.div`
    position: relative;
    display: flex;
    width: 150px;
    height: 150px;
    align-items: center;
    justify-content: center;
    margin-bottom: 13px;
`

const ExecutiveInfoWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 265px;
`

const Position = styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
`

const Name = styled.p`
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 10px 0px 0px 0px;
`

const Romaji = styled.p`
    font-weight: 400;
    margin: 15px 0px 20px 0px;
`

const Info = styled.p`
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    color: #4e4e4e;
    margin: 0px;
`

const ExecutiveCardWide = styled.div`
    display: flex;
    width: 512px;
    margin: 45px;
`

const ExecutiveImageWrapperWide = styled.div`
    position: relative;
    display: flex;
    width: 150px;
    height: 150px;
    align-items: center;
    justify-content: center;
`

const ExecutiveInfoWrapperWide = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: 30px;
`

const MobileCompanyExecutive = props => {
    const breakPoints = useBreakpoint()

    return (
        <React.Fragment>
            {breakPoints.smartphone ? (
                <ExecutiveCard>
                    <ExecutiveImageWrapper>
                        <Img fixed={props.img} />
                    </ExecutiveImageWrapper>
                    <ExecutiveInfoWrapper>
                        {props.position.map((position, index) => (
                            <Position key={index}>{position}</Position>
                        ))}
                        <Name>{props.name}</Name>
                        <Romaji>{props.romaji}</Romaji>
                        {props.info.map((information, index) => (
                            <Info key={index}>{information}</Info>
                        ))}
                    </ExecutiveInfoWrapper>
                </ExecutiveCard>
            ) : null}
            {breakPoints.mobileWide ? (
                <ExecutiveCardWide>
                    <ExecutiveImageWrapperWide>
                        <Img fixed={props.img} />
                    </ExecutiveImageWrapperWide>
                    <ExecutiveInfoWrapperWide>
                        {props.position.map((position, index) => (
                            <Position key={index}>{position}</Position>
                        ))}
                        <Name>{props.name}</Name>
                        <Romaji>{props.romaji}</Romaji>
                        {props.info.map((information, index) => (
                            <Info key={index}>{information}</Info>
                        ))}
                    </ExecutiveInfoWrapperWide>
                </ExecutiveCardWide>
            ) : null}
        </React.Fragment>
    )
}

export default MobileCompanyExecutive

import React from "react"
import styled from "styled-components"
import IndexTopicTitle from "../atoms/IndexTopicTitle"
import CompanyExecutive from "../molecules/CompanyExecutive"
import { HorizontalLine } from "../atoms/Decoration"
import Spacer from "../atoms/Spacer"
import CompanyImages from "../../Images/CompanyImages"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import CompanyMember from "../molecules/CompanyMember"

const Outer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const TabletExecutivesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px auto 40px auto;
`

const PCExecutivesWrapper = styled.div`
    display: flex;
    margin: 10px auto 40px auto;
`

const PCMembersOuter = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const PCMembersWrapper = styled.div`
    display: flex;
    width: 1280px;
    flex-wrap: wrap;
`

const TabletMembersWrapper = styled.div`
    display: flex;
    width: 900px;
    flex-wrap: wrap;
`

const CompanyMembers = ({ posts }) => {
    const breakPoints = useBreakpoint()

    // const ExecutivesData = [
    //     {
    //         img: CompanyImages("ceoImg"),
    //         position: ["代表取締役CEO", ""],
    //         name: "下岡 純一郎",
    //         romaji: "JUNICHIRO SHIMOOKA",
    //         info: [
    //             "戸畑高校、九州大学理学部、京都大学大学院卒。",
    //             "P&G、博報堂コンサルティングを経て、2017年より株式会社クアンドを創業。九州工業大学客員准教授。",
    //         ],
    //     },
    // ]

    const AdvisorsData = [
        {
            img: CompanyImages("furunagaImg"),
            position: [
                "日本アイ・ビー・エム株式会社",
                "戦略コンサルティング&デザイン統括",
                "理事パートナー",
            ],
            name: "古長 由里子",
            romaji: "YURIKO FURUNAGA",
            info: [
                "IBMにおいて、企業のデジタルイノベーション推進、テクノロジー人材育成支援を担当。",
                "クラウド・コンピューティング分野のマーケティングやビジネス開発の経験を活かして、産学官連携の地域オープン・イノベーションや大企業×スタートアップ企業の協業を推進する。",
            ],
        },
        {
            img: CompanyImages("tokkiImg"),
            position: ["事業戦略アドバイザー/エバンジェリスト", ""],
            name: "常盤木 龍治",
            romaji: "RYUJI TOKIWAGI",
            info: [
                "EBILAB/LiLz株式会社/株式会社レキサスなど複数社にて最高戦略責任者/CTO/エバンジェリストなど兼任",
                "",
            ],
        },
    ]

    return (
        <Outer>
            <IndexTopicTitle
                title="MEMBERS"
                subtitle="メンバー"
                position="center"
            />
            {!breakPoints.wxga ? (
                <PCMembersOuter>
                    <TabletMembersWrapper>
                        {posts.map((post, index) => (
                            <CompanyMember
                                key={index}
                                thumbnailPath={post.thumbnailPath}
                                position={post.position}
                                jpName={post.jpName}
                                enName={post.enName}
                            />
                        ))}
                    </TabletMembersWrapper>
                </PCMembersOuter>
            ) : null}
            {breakPoints.wxga ? (
                <PCMembersOuter>
                    <PCMembersWrapper>
                        {posts.map((post, index) => (
                            <CompanyMember
                                key={index}
                                thumbnailPath={post.thumbnailPath}
                                position={post.position}
                                jpName={post.jpName}
                                enName={post.enName}
                            />
                        ))}
                    </PCMembersWrapper>
                </PCMembersOuter>
            ) : null}
            <HorizontalLine />
            <Spacer height="80px" />
            <IndexTopicTitle
                title="ADVISOR"
                subtitle="外部アドバイザー"
                position="center"
            />
            {!breakPoints.wxga ? (
                <TabletExecutivesWrapper>
                    {AdvisorsData.map((a, idx) => (
                        <CompanyExecutive
                            key={idx}
                            img={a.img}
                            position={a.position}
                            name={a.name}
                            romaji={a.romaji}
                            info={a.info}
                        />
                    ))}
                </TabletExecutivesWrapper>
            ) : null}
            {breakPoints.wxga ? (
                <PCExecutivesWrapper>
                    {AdvisorsData.map((a, idx) => (
                        <CompanyExecutive
                            key={idx}
                            img={a.img}
                            position={a.position}
                            name={a.name}
                            romaji={a.romaji}
                            info={a.info}
                        />
                    ))}
                </PCExecutivesWrapper>
            ) : null}
        </Outer>
    )
}

export default CompanyMembers

import { useStaticQuery, graphql } from "gatsby"

const MobileCompanyImages = (request) => {
    const data = useStaticQuery(graphql`
        query {
            ceoImg: file(relativePath: { eq: "PC/ceoImg.jpg" }) {
                childImageSharp {
                    fixed(width: 150, height: 150) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            ctoImg: file(relativePath: { eq: "PC/ctoImg.jpg" }) {
                childImageSharp {
                    fixed(width: 218, height: 218) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            furunagaImg: file(relativePath: { eq: "PC/furunagaImg.png" }) {
                childImageSharp {
                    fixed(width: 150, height: 150) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            tokkiImg: file(relativePath: { eq: "PC/tokkiImg.jpg" }) {
                childImageSharp {
                    fixed(width: 150, height: 150) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
        }
    `)

    return data[request].childImageSharp.fixed
}

export default MobileCompanyImages
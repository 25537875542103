import React from "react"
import styled from "styled-components"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const MemberOuter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 320px;
    height: 340px;
`

const TabletMemberOuter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    height: 340px;
`

const MemberImgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const MemberIcon = styled.img`
    width: 175px;
`

const MemberInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 175px;
`

const MemberPosition = styled.p`
    font-size: 14px;
    font-weight: 400;
    margin-top: 25px;
`

const JPName = styled.p`
    font-size: 26px;
    font-weight: 400;
    margin-top: 20px;
`

const ENName = styled.p`
    font-size: 14px;
    margin-top: 12px;
    font-weight: 400;
`

const CompanyMember = props => {

  const breakPoints = useBreakpoint()

  return (
    <React.Fragment>
      {!breakPoints.wxga ? (
        <TabletMemberOuter>
          <MemberImgWrapper>
            <MemberIcon src={props.thumbnailPath} loading="lazy" />
          </MemberImgWrapper>
          <MemberInfoWrapper>
            <MemberPosition>{props.position}</MemberPosition>
            <JPName>{props.jpName}</JPName>
            {props.jpName !== props.enName && <ENName>{props.enName}</ENName>}
          </MemberInfoWrapper>
        </TabletMemberOuter>
      ) : null}
      {breakPoints.wxga ? (
        <MemberOuter>
          <MemberImgWrapper>
            <MemberIcon src={props.thumbnailPath} loading="lazy" />
          </MemberImgWrapper>
          <MemberInfoWrapper>
            <MemberPosition>{props.position}</MemberPosition>
            {props.jpName === props.enName ? (
              <JPName>{props.enName}</JPName>
            ) : (
              <React.Fragment>
                <JPName>{props.jpName}</JPName>
                <ENName>{props.enName}</ENName>
              </React.Fragment>
            )}
          </MemberInfoWrapper>
        </MemberOuter>
      ) : null}
    </React.Fragment>
  )
}

export default CompanyMember

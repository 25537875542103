import React from "react"
import styled from "styled-components"

const Outer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 50px;
`

const LeftTitle = styled.h2`
    display: inline-block;
    width: 87%;
    font-size: 40px;
    font-weight: 700;
    margin: 8px 0px 8px 0px;
`

const LeftSubtitle = styled.h3`
    display: inline-block;
    width: 87%;
    letter-spacing: 1px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0px;
`

const CenterTitle = styled.h2`
    display: inline-block;
    width: 100%;
    font-size: 40px;
    font-weight: 700;
    margin: 8px 0px 8px 0px;
    text-align: center;
`

const CenterSubtitle = styled.h3`
    display: inline-block;
    width: 100%;
    letter-spacing: 1px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0px;
`

const IndexTopicTitle = (props) => {

    return (
        <Outer>
            {(props.position === "left") &&
                <React.Fragment>
                    <LeftTitle>{props.title}</LeftTitle>
                    <LeftSubtitle>{props.subtitle}</LeftSubtitle>
                </React.Fragment>
            }
            {(props.position === "center") &&
                <React.Fragment>
                    <CenterTitle>{props.title}</CenterTitle>
                    <CenterSubtitle>{props.subtitle}</CenterSubtitle>
                </React.Fragment>
            }
        </Outer>
    )
}

export default IndexTopicTitle
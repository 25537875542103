import React from "react"
import styled from "styled-components"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const MemberOuter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px;
    height: 280px;
`

const MemberOuterWide = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    height: 280px;
`


const MemberImgWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`

const MemberIcon = styled.img`
    width: 120px;
`

const MemberInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`

const MemberPosition = styled.p`
    font-size: 14px;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 8px;
`

const JPName = styled.p`
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
`

const ENName = styled.p`
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    text-align: center;
`


const MobileCompanyMember = (props) => {

    const breakPoints = useBreakpoint()

    return (
        <React.Fragment>
            {breakPoints.smartphone
                ?
                    <MemberOuter>
                        <MemberImgWrapper>
                            <MemberIcon src={props.thumbnailPath} loading="lazy" />
                        </MemberImgWrapper>
                        <MemberInfoWrapper>
                            <MemberPosition>{props.position}</MemberPosition>
                            {(props.jpName === props.enName)
                                            ?
                                                <JPName>{props.enName}</JPName>
                                            :
                                                <React.Fragment>
                                                    <JPName>{props.jpName}</JPName>
                                                    <ENName>{props.enName}</ENName>
                                                </React.Fragment>
                                        }
                        </MemberInfoWrapper>
                    </MemberOuter>
                :
                    null
            }
            {breakPoints.mobileWide
                ?
                    <MemberOuterWide>
                        <MemberImgWrapper>
                            <MemberIcon src={props.thumbnailPath} loading="lazy" />
                        </MemberImgWrapper>
                        <MemberInfoWrapper>
                            <MemberPosition>{props.position}</MemberPosition>
                            {(props.jpName === props.enName)
                                            ?
                                                <JPName>{props.enName}</JPName>
                                            :
                                                <React.Fragment>
                                                    <JPName>{props.jpName}</JPName>
                                                    <ENName>{props.enName}</ENName>
                                                </React.Fragment>
                                        }
                        </MemberInfoWrapper>
                    </MemberOuterWide>
                :
                    null
            }
        </React.Fragment>
    )
}

export default MobileCompanyMember
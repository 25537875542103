import React from "react"
import styled from "styled-components"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import MobileCompanyExecutive from "../molecules/MobileCompanyExecutive"
import MobileCompanyMember from "../molecules/MobileCompanyMember"
import MobileCompanyImages from "../../Images/MobileCompanyImages"
import MobileIndexTopicTitle from "../atoms/MobileIndexTopicTitle"

const Outer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const ExecutivesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px auto 40px auto;
`

const MembersOuter = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const MembersWrapper = styled.div`
    display: flex;
    width: 300px;
    flex-wrap: wrap;
`

const MembersWrapperWide = styled.div`
    display: flex;
    width: 600px;
    flex-wrap: wrap;
`

const MobileCompanyMembers = ({ posts }) => {
    const breakPoints = useBreakpoint()

    // const ExecutivesData = [
    //     {
    //         img: MobileCompanyImages("ceoImg"),
    //         position: ["代表取締役CEO", ""],
    //         name: "下岡 純一郎",
    //         romaji: "JUNICHIRO SHIMOOKA",
    //         info: [
    //             "戸畑高校、九州大学理学部、京都大学大学院卒。",
    //             "P&G、博報堂コンサルティングを経て、2017年より株式会社クアンドを創業。九州工業大学客員准教授。",
    //         ],
    //     },
    // ]

    const AdvisorsData = [
        {
            img: MobileCompanyImages("furunagaImg"),
            position: [
                "日本アイ・ビー・エム株式会社",
                "戦略コンサルティング&デザイン統括",
                "理事パートナー",
            ],
            name: "古長 由里子",
            romaji: "YURIKO FURUNAGA",
            info: [
                "IBMにおいて、企業のデジタルイノベーション推進、テクノロジー人材育成支援を担当。",
                "クラウド・コンピューティング分野のマーケティングやビジネス開発の経験を活かして、産学官連携の地域オープン・イノベーションや大企業×スタートアップ企業の協業を推進する。",
            ],
        },
        {
            img: MobileCompanyImages("tokkiImg"),
            position: ["事業戦略アドバイザー/エバンジェリスト", ""],
            name: "常盤木 龍治",
            romaji: "RYUJI TOKIWAGI",
            info: [
                "EBILAB/LiLz株式会社/株式会社レキサスなど複数社にて最高戦略責任者/CTO/エバンジェリストなど兼任",
                "",
            ],
        },
    ]

    return (
        <Outer>
            <MobileIndexTopicTitle
                title="MEMBERS"
                subtitle="メンバー"
                position="center"
            />
            {breakPoints.smartphone ? (
                <MembersOuter>
                    <MembersWrapper>
                        {posts.map((post, index) => (
                            <MobileCompanyMember
                                key={index}
                                thumbnailPath={post.thumbnailPath}
                                position={post.position}
                                jpName={post.jpName}
                                enName={post.enName}
                            />
                        ))}
                    </MembersWrapper>
                </MembersOuter>
            ) : null}
            {breakPoints.mobileWide ? (
                <MembersOuter>
                    <MembersWrapperWide>
                        {posts.map((post, index) => (
                            <MobileCompanyMember
                                key={index}
                                thumbnailPath={post.thumbnailPath}
                                position={post.position}
                                jpName={post.jpName}
                                enName={post.enName}
                            />
                        ))}
                    </MembersWrapperWide>
                </MembersOuter>
            ) : null}

            <MobileIndexTopicTitle
                title="ADVISOR"
                subtitle="外部アドバイザー"
                position="center"
            />
            <ExecutivesWrapper>
                {AdvisorsData.map((a, idx) => (
                    <MobileCompanyExecutive
                        key={idx}
                        img={a.img}
                        position={a.position}
                        name={a.name}
                        romaji={a.romaji}
                        info={a.info}
                    />
                ))}
            </ExecutivesWrapper>
        </Outer>
    )
}

export default MobileCompanyMembers

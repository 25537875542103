import React from "react"
import styled from "styled-components"
import MobileFooterContent from "../molecules/MobileFooterContent"

const MobileFooterOuter = styled.div``

const MobileFooterDecoration = styled.div`
    width: 100%;
    height: 54px;
    background-color: black;
`

const MobileFooter = () => {

    return(
        <MobileFooterOuter>
            <MobileFooterDecoration />
            <MobileFooterContent />
        </MobileFooterOuter>
    )
}

export default  MobileFooter
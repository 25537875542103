import React from 'react'
import styled from "styled-components"
import Img from 'gatsby-image'
import FluidImages from '../../Images/FluidImages'
import IndexHeroMovieSrc from '../../../../static/SP_720_min.mp4'
import MobileIndexHeroContents from '../molecules/MobileIndexHeroContents'

const IndexRoot = styled.div`
    display: inline-block;
    width: 100%;
    height: 100vh;
    background: black;
`

const Root = styled.div`
    display: inline-block;
    width: 100%;
    background: black;
`

const HeroTitleWrapper = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    width: 100%;
    height: 78vw;
    align-items: center;
    justify-content: center;
`

const HeroTitle = styled.h1`
    text-align: center;
    color: white;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 12px;
`

const IndexHeroMovie = styled.video`
    width: 100%;
    height: 90vh;
    object-fit: cover;
`

const IndexHeroMovieEffect = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    display: inline-block;
    width: 100%;
    height: 90vh;
    opacity: 0.4;
    background-size: 4px 4px;
    background-image: -webkit-gradient(linear, 0 0, 100% 100%, color-stop(0.25, #000), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, #000), color-stop(0.75, #000), color-stop(0.75, transparent), to(transparent));
`

const IndexHeroContentsWrapper = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    display: inline-block;
    width: 100%;
    height: 100vh;
`

const MobileHero = ({ page }) => {

    if (page === 'INDEX') {
        return (
            <IndexRoot>
                <IndexHeroMovie muted playsInline src={IndexHeroMovieSrc} autoPlay loop />
                <IndexHeroMovieEffect />
                <IndexHeroContentsWrapper>
                    <MobileIndexHeroContents />
                </IndexHeroContentsWrapper>
            </IndexRoot>
        )
    } else {
        return (
            <Root>
                <Img fluid={FluidImages(page)} style={{ height: '78vw' }} />
                <HeroTitleWrapper>
                    <HeroTitle>{page}</HeroTitle>
                </HeroTitleWrapper>
            </Root>
        )
    }
}

export default MobileHero

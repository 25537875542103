import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const ExecutiveCard = styled.div`
    display: flex;
    width: 512px;
    margin: 45px;
`

const ExecutiveImageWrapper = styled.div`
    position: relative;
    display: flex;
    width: 218px;
    height: 218px;
    align-items: center;
    justify-content: center;
`

const ExecutiveInfoWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: 30px;
`

const Position = styled.p`
    font-size: 14px;
    font-weight: 400;
    margin: 3px 0px;
`

const Name = styled.p`
    font-size: 36px;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 10px 0px 0px 0px;
`

const Romaji = styled.p`
    font-weight: 400;
    margin: 10px 0px 20px 0px;
`

const Info = styled.p`
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    color: #4e4e4e;
    margin: 0px;
`

const CompanyExecutive = props => {
    return (
        <ExecutiveCard>
            <ExecutiveImageWrapper>
                <Img fixed={props.img} />
            </ExecutiveImageWrapper>
            <ExecutiveInfoWrapper>
                {props.position.map((position, index) => (
                    <Position key={index}>{position}</Position>
                ))}
                <Name>{props.name}</Name>
                <Romaji>{props.romaji}</Romaji>
                {props.info.map((information, index) => (
                    <Info key={index}>{information}</Info>
                ))}
            </ExecutiveInfoWrapper>
        </ExecutiveCard>
    )
}

export default CompanyExecutive

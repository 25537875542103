import { FormatBold } from "@material-ui/icons"
import { useBreakpoint } from "gatsby-plugin-breakpoints/BreakpointProvider"
import React from "react"
import styled from "styled-components"
import Spacer from "../../PC/atoms/Spacer"

const Outer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const CompanyInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    min-width: 280px;
    justify-content: center;
    height: auto;
    padding-bottom: 80px;
`

const CompanyInfoOuter = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`
const CompanyInformation = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
`

const CompanyTitle = styled.h2`
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 20px 0px 20px 0px;
`

const CompanyTable = styled.table`
    width: 100%;
`

const CompanyBody = styled.tbody`
    width: 100%;
`

const CompanyTr = styled.tr`
    width: 100%;
    font-size: 16px;
`

const CompanyField = styled.td`
    width: 30%;
    font-size: 14px;
    font-weight: 400;
`

const CompanyData = styled.td`
    width: 70%;
    padding: 10px 0 10px 0;
`

const CompanyDetails = styled.p`
    line-height: 27px;
    font-weight: 400;
`

const CompanyLocation = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
`

const Map = styled.iframe`
    min-width: 280px;
    height: 270px;
    border: 0;
`

const CompanyLocationWide = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    padding-top: 90px;
`

const CompanyInfoWrapperWide = styled.div`
    display: flex;
    width: 85%;
    justify-content: center;
    height: auto;
    padding-bottom: 80px;
`


const MobileCompanyInfo = (props) => {

    const breakPoints = useBreakpoint()

    const AboutCompany = [
        {
            field: "会社名：",
            data: [
                "株式会社クアンド",
                "(英文社名 : QUANDO, Inc.)"
            ]
        },
        {
            field: "設立：",
            data: ["2017年4月25日"]
        },
        {
            field: "所在地：",
            data: [
                <b>北九州オフィス</b>,
                "〒805-0002",
                "福岡県北九州市八幡東区枝光2-7-32",
                <b>福岡オフィス</b>,
                "〒810-0041",
                "福岡市中央区大名2-4-22 3階 OnRAMP内",
          ]
        },
        {
            field: "事業内容：",
            data: [
                "現場向けリモートコラボレーションツール「SynQ Remote（シンクリモート）」の開発および提供",
            ]
        },
        {
            field: "資本金：",
            data: ["63,900,000円"]
        }
    ]

    return (

        <Outer>
                <CompanyInfoOuter>
                        {breakPoints.smartphone
                            ?
                                <CompanyInfoWrapper>
                                    <CompanyTitle>会社案内</CompanyTitle>
                                    <CompanyTable>
                                        <CompanyBody>
                                            {AboutCompany.map((tableRowData,index) => (
                                                <CompanyTr key={`CompanyTr${index}`}>
                                                    <CompanyField>{tableRowData.field}</CompanyField>
                                                    <CompanyData>
                                                        {tableRowData.data.map((data,index) => (
                                                            <CompanyDetails key={`CompanyDetails${index}`}>{data}</CompanyDetails>
                                                        ))}
                                                    </CompanyData>
                                                </CompanyTr>
                                            ))}
                                        </CompanyBody>
                                    </CompanyTable>
                                    <CompanyLocation>
                                        <Map src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.265475744931!2d130.8093193510139!3d33.88281608055747!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3543c7e9fcc7cf97%3A0x8230f392fd07cb1e!2z44CSODA1LTAwMDIg56aP5bKh55yM5YyX5Lmd5bee5biC5YWr5bmh5p2x5Yy65p6d5YWJ77yS5LiB55uu77yX4oiS77yT77yS!5e0!3m2!1sja!2sjp!4v1600333378830!5m2!1sja!2sjp" allowfullscreen="" frameborder="0" aria-hidden="true" tabindex="0"></Map>
                                    </CompanyLocation>
                                </CompanyInfoWrapper>
                            :
                                <CompanyInfoWrapperWide>
                                    <CompanyInformation>
                                        <CompanyTitle>会社案内</CompanyTitle>
                                        <CompanyTable>
                                            <CompanyBody>
                                                {AboutCompany.map((tableRowData,index) => (
                                                    <CompanyTr key={`CompanyTr${index}`}>
                                                        <CompanyField>{tableRowData.field}</CompanyField>
                                                        <CompanyData>
                                                            {tableRowData.data.map((data,index) => (
                                                                <CompanyDetails key={`CompanyDetails${index}`}>{data}</CompanyDetails>
                                                            ))}
                                                        </CompanyData>
                                                    </CompanyTr>
                                                ))}
                                            </CompanyBody>
                                        </CompanyTable>
                                    </CompanyInformation>
                                    <CompanyLocationWide>
                                        <Map src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.265475744931!2d130.8093193510139!3d33.88281608055747!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3543c7e9fcc7cf97%3A0x8230f392fd07cb1e!2z44CSODA1LTAwMDIg56aP5bKh55yM5YyX5Lmd5bee5biC5YWr5bmh5p2x5Yy65p6d5YWJ77yS5LiB55uu77yX4oiS77yT77yS!5e0!3m2!1sja!2sjp!4v1600333378830!5m2!1sja!2sjp" allowfullscreen="" frameborder="0" aria-hidden="true" tabindex="0"></Map>
                                    </CompanyLocationWide>
                                </CompanyInfoWrapperWide>
                        }
                </CompanyInfoOuter>
            <Spacer height="75px" />
        </Outer>
    )
}

export default MobileCompanyInfo

import React from "react"
import styled from "styled-components"
import { FaceBookIconSVG, NoteIconSVG, TwitterIconSVG } from "../../Images/svg"
import { SNSIconButton } from "../../PC/atoms/Button"
import { MobileGoToTopButton } from "../atoms/MobileButton"

const MobileFooterContentWrapper = styled.div`
    width: 100%;
    height: 163px;
    background-color: #393939;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
`

const SnsButtons = styled.div`
    display: flex;
    width: 120px;
    height: 24px;
    justify-content: space-between;
    position: absolute;
    bottom: 49px;
`

const Copyright = styled.p`
    color: white;
    font-size: 12px;
    line-height: 17px;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 16px;
`



const MobileFooterContent = () => {

    return(
        <MobileFooterContentWrapper>
            <MobileGoToTopButton />
                <SnsButtons>
                    <SNSIconButton linkto="https://www.facebook.com/QUANDOInc/">
                        <FaceBookIconSVG />
                    </SNSIconButton>
                    <SNSIconButton linkto="https://twitter.com/SynQRemote">
                        <TwitterIconSVG />
                    </SNSIconButton>
                    <SNSIconButton linkto="https://note.com/synq">
                        <NoteIconSVG />
                    </SNSIconButton>
                </SnsButtons>
                <Copyright>© 2020 QUANDO Inc. All Rights Reserved.</Copyright>
        </MobileFooterContentWrapper>
    )
}

export default MobileFooterContent
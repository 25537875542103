import React from 'react'
import styled from "styled-components"
import { IndexHeroScroll } from '../../PC/atoms/Decoration'

const Root = styled.div`
    display: inline-block;
    width: 100%;
    height: 100%;
`

const SloganWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 54%;
`

const Slogan = styled.h1`
    text-align: center;
    color: white;
    font-size: 36px;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: 8px;
`

const OtherContentsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 46%;
`

const MobileIndexHeroContents = () => {

    return (
        <Root>
            <SloganWrapper>
                <Slogan>UPDATE<br />THE<br />INDUSTRY</Slogan>
            </SloganWrapper>
            <OtherContentsWrapper>
                <IndexHeroScroll />
            </OtherContentsWrapper>
        </Root>
    )
}

export default MobileIndexHeroContents

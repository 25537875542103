import Pages from "../data/PagesData"

const judgePage = (pageName) => {

    for (let index = 0 ; index < Pages.length ; index++) {
        if (Pages[index].name === pageName) {
            return Pages[index]
        }
    }
}

export default judgePage